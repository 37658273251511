import React from 'react'

const MonitorTaskContainer = Loader.loadBusinessComponent('MonitorTasks', 'MonitorTaskContainer')

const KeyPersonMonitorTask = () => (
  <MonitorTaskContainer 
    libType={1}
    taskType='101501'
    currentMenu='keyPersonnelTaskView'
    moduleName='keyPersonnelTaskManage'
  />
)

export default KeyPersonMonitorTask